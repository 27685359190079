import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 查询船舶列表
export const ShipOwnerPage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Page`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const PageStatus = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/PageStatus`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 添加船舶信息
export const ShipOwnerAdd = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Add`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 编辑船舶信息
export const ShipOwnerEdit = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Edit`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 暂存信息
export const StagingSet = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/StagingSet`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取暂存信息
export const StagingGet = () => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/StagingGet`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询船舶明细
export const ShipOwnerDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Detail`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 提交审核
export const ShipOwnerSubmit = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Submit`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 提交审核
export const ShipOwnerSubmitList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/SubmitList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 审核驳回
export const ShipOwnerReject = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Reject`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 审核同意
export const ShipOwnerAgree = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Agree`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 导入船东
export const Import = (params,isRecipient) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Import?isRecipient=${isRecipient}`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


export const  ImportList = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/ImportList?isRecipient=${type}`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const  ImportListImgSrc = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/ImportListImgSrc`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
} 


// 导出船东
export const Export = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Export`, params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除船东
export const Delete = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/Delete`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载导入模板
export const DownTemplate = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v3/ShipOwner/DownTemplate`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 船东绑定船舶--校验
export const RelationBindCheck = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/RelationBindCheck`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除绑定关系
export const RelationBindDel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/RelationBindDel`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 船东绑定船舶列表
export const RelationBindPage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/RelationBindPage`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

 

// 服务商合作伙伴船东
export const PagePartner = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/PagePartner`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


// 服务商合作伙伴船东
export const UpdateTaxRegisterShip = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/ShipOwner/UpdateTaxRegister`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}